// React
import React, { Suspense } from 'react';

// Components & Hooks
import MemberDetails from './MemberDetails/MemberDetails';
import Enrollment from './Enrollment/Enrollment';
import Settings from './Settings/Settings';
import UserInformation from './Agreement/UserInformation';
import Login from './Login';
import Signup from './Signup';
import ConfirmEmail from './ConfirmEmail';
import PasswordReset from './PasswordReset';
import PasswordResetSend from './PasswordResetSend';
import Reports from './Reports/Reports';
import Devices from './Devices/Devices';
import ProspectDetails from './ProspectDetails/ProspectDetails';
import ProspectList from './ProspectList/ProspectList';
import Home from './Home';

import { StitchAuthProvider, useStitchAuth } from './StitchAuth';
import { StitchMembersProvider } from './StitchMembers';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { GeneralParamsProvider } from './GeneralParams';
import Admin from './Admin/Admin';

window.YTConfig = {
  host: 'https://www.youtube.com',
};

App.propTypes = {};
export default function App() {
  const MemberAccess = React.lazy(() =>
    import('./MemberAccess/MemberAccess'),
  );
  const MemberVideo = React.lazy(() =>
    import('./MemberAccess/MemberVideo'),
  );
  const ReportReadings = React.lazy(() =>
    import('./MemberAccess/ReportReadings'),
  );

  const OneTimeAlert = React.lazy(() =>
    import('./AlertDoctor/OneTimeAlert'),
  );

  return (
    <StitchAuthProvider>
      <StitchMembersProvider>
        <GeneralParamsProvider>
          <Router>
            <Switch>
              <Route path="/confirmemail">
                <ConfirmEmail />
              </Route>
              <Route path="/passwordreset">
                <PasswordReset />
              </Route>
              <Route path="/passwordresetsend">
                <PasswordResetSend />
              </Route>
              <Route path="/signup/:client">
                <Signup />
              </Route>
              <Route path="/signup">
                <Signup />
              </Route>
              <Route path="/login/:client">
                <Login />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/member-access/report-readings/:memberId">
                <Suspense fallback={<div>Loading...</div>}>
                  <ReportReadings />
                </Suspense>
              </Route>
              <Route path="/member-access/telehealth/:roomName/:memberName">
                <Suspense fallback={<div>Loading...</div>}>
                  <MemberVideo />
                </Suspense>
              </Route>
              <Route path="/doctoralerts/:alertId">
                <Suspense fallback={<div>Loading...</div>}>
                  <OneTimeAlert />
                </Suspense>
              </Route>
              <Route path="/member-access/:memberToken">
                <Suspense fallback={<div>Loading...</div>}>
                  <MemberAccess />
                </Suspense>
              </Route>
              <PrivateRoute path="/userinformation">
                <UserInformation />
              </PrivateRoute>
              <PrivateRoute path="/settings">
                <Settings />
              </PrivateRoute>
              <PrivateRoute path="/enrollment/:viewDefault">
                <Enrollment />
              </PrivateRoute>
              <PrivateRoute path="/devices/:viewDefault">
                <Devices />
              </PrivateRoute>
              <PrivateRoute path="/member-details/:memberId">
                <MemberDetails />
              </PrivateRoute>
              <PrivateRoute path="/reports">
                <Reports />
              </PrivateRoute>
              <PrivateRoute path="/prospect-details/:prospectId">
                <ProspectDetails />
              </PrivateRoute>
              <PrivateRoute path="/prospects">
                <ProspectList />
              </PrivateRoute>
              <PrivateRoute path="/admin">
                <Admin />
              </PrivateRoute>
              <PrivateRoute path="/home/:viewDefault">
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/home">
                <Home />
              </PrivateRoute>
              <Route path="*">
                <Redirect to={{ pathname: '/login' }} />
              </Route>
            </Switch>
          </Router>
        </GeneralParamsProvider>
      </StitchMembersProvider>
    </StitchAuthProvider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { isLoggedIn } = useStitchAuth();

  if (isLoggedIn) {
    return <Route {...rest} render={() => children} />;
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
}
