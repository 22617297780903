import React, { useState, useEffect } from 'react';
import {
  Menu,
  Header,
  Divider,
  Dimmer,
  Loader,
  Grid,
  Dropdown,
  Icon,
} from 'semantic-ui-react';
import { StitchMembersProvider } from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import ContractWarning from '../Common/ContractWarning';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import ContentTable from './ContentTable/ContentTable';
import { useHistory } from 'react-router-dom';
import { useStitchAuth } from '../StitchAuth';
import { useGeneralParams } from '../GeneralParams';
import { platformServices } from '../Common/Values';

export default function MemberList() {
  const { userCustomData, otherUsers } = useStitchAuth();
  const {
    showParam,
    setShowParam,
    sortParam,
    setSortParam,
    listFilter,
    setListFilter,
    setPageNum,
  } = useGeneralParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [physicianList, setPhysicianList] = useState([]);
  const groupServices = userCustomData?.services || [];
  const listFilterMap = {
    'all-members': 'All Enrolled Members',
    'my-members': 'My Members',
    disenrolled: 'Disenrolled Members',
    'new-members': 'New Members',
    'hcc-suspected': 'Members with HCCs Suspected',
    'hcc-in-progress': 'Members with HCCs being Verified',
    'hcc-confirmed': 'Members with HCCs Confirmed',
    'todo-99454': 'Members who Need Measurements (for CPT 99454)',
    'todo-99457':
      'Members who Need Communication or Review (for CPT 99457)',
    'todo-99457-call':
      'Members who Need Interactive Communication (for CPT 99457)',
    'todo-99457-review':
      'Members who Need 20 Minutes Review (for CPT 99457)',
    'todo-99458':
      'Members who Need Additional 20 min Reviews (for CPT 99458); 99457 Completed',
      'todo-99458-call':
      'Members who Need Interactive Communication (for CPT 99458); 99457 Completed',
    'todo-99458-review':
      'Members who Need Additional 20 Minutes Review (for CPT 99458); 99457 Completed',
    'ccm-members': 'CCM Members',
    'htn-list':
      'Members with Hypertension (Systolic > 140 or Diastolic > 90)',
    'obesity-list': 'Members with BMI > 40',
    'dm-list': 'Members with Diabetes',
    'ckd-list': 'Members with Chronic Kidney Disease (Stage 3, 4)',
    'esrd-list': 'Members with End-Stage Renal Disease',
    'chf-list': 'Members with Congestive Heart Failure',
    'needs-review': 'All Members with Warnings but not Reviewed Yet',
    'needs-review-mine':
      'My Members with Warnings but not Reviewed Yet',
    'msg-unread': 'Members with Unread Text Messages',
    'inactive-2-days':
      'Members with No Activities for at least 2 days',
    'inactive-5-days':
      'Members with No Activities for at least 5 days',
    'frequent-ed': 'Members with Frequent ED Visits',
    'recent-inpatient': 'Members with Recent Inpatient Stays (3 mo.)',
    'just-discharged': 'Members just Discharged (1 mo.)',
    'high-cost-list': 'Members with Top 5% Medical Spend',
    'event-today': 'Members with Scheduled Events Today',
    'chase-list': 'Members with open To-Do items',
    'sms-available':
      'Members with valid mobile phone numbers and opted-in for SMS',
    'sms-not-available':
      "Members who don't have valid phone numbers or blocked our number",
    'with-memo': 'Members with user-written memos',
    'missed-call':
      "Members who called the company number and didn't receive a call back",
  };
  const sortParams = [
    {
      key: 'urgencyDesc',
      value: 'urgencyDesc',
      text: 'TO-DO (Most -> Least Urgent)',
    },
    {
      key: 'urgencyAsc',
      value: 'urgencyAsc',
      text: 'TO-DO (Least -> Most Urgent)',
    },
    {
      key: 'measureDateDesc',
      value: 'measureDateDesc',
      text: 'LATEST MEASURE DATE (Latest -> Oldest)',
    },
    {
      key: 'measureDateAsc',
      value: 'measureDateAsc',
      text: 'LATEST MEASURE DATE (Oldest -> Latest)',
    },
    {
      key: 'activityDesc',
      value: 'activityDesc',
      text: 'MEASURE FREQUENCY (High -> Low)',
    },
    {
      key: 'activityAsc',
      value: 'activityAsc',
      text: 'MEASURE FREQUENCY (Low -> High)',
    },
    { key: 'nameDesc', value: 'nameDesc', text: 'NAME (Z -> A)' },
    { key: 'nameAsc', value: 'nameAsc', text: 'NAME (A -> Z)' },
    /*{
      key: 'bodyWeightAsc',
      value: 'bodyWeightAsc',
      text: 'BODY WEIGHT (Low -> High)',
    },
    {
      key: 'bodyWeightDesc',
      value: 'bodyWeightDesc',
      text: 'BODY WEIGHT (High -> Low)',
    },
    {
      key: 'bloodPressureAsc',
      value: 'bloodPressureAsc',
      text: 'SYSTOLIC BP (Low -> High)',
    },
    {
      key: 'bloodPressureDesc',
      value: 'bloodPressureDesc',
      text: 'SYSTOLIC BP (High -> Low)',
    },
    {
      key: 'heartRateAsc',
      value: 'heartRateAsc',
      text: 'HEART RATE (Low -> High)',
    },
    {
      key: 'heartRateDesc',
      value: 'heartRateDesc',
      text: 'HEART RATE (High -> Low)',
    },*/
  ];

  const handleLFChange = (newLF) => {
    history.push({
      pathname: '/home/' + newLF,
    });
    setPageNum(0);
    setListFilter(newLF);
  };

  useEffect(() => {
    if (userCustomData && otherUsers) {
      setPhysicianList(
        (otherUsers || [])
          .filter((x) => x.id !== userCustomData.id)
          .map((x) => {
            return {
              text: x.name + ' (' + x.email + ')',
              value: x.id,
              key: x.id,
            };
          }),
      );
      setLoading(false);
    }
  }, [userCustomData, otherUsers]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        {showSideMenu && (
          <div className="channels box">
            <Header
              as="h4"
              style={{ paddingLeft: '1em', color: '#fff' }}
            >
              View Options
            </Header>
            <Menu
              vertical
              secondary
              inverted
              style={{ background: 'transparent' }}
            >
              <Menu.Item
                active={listFilter === 'all-members'}
                onClick={() => handleLFChange('all-members')}
              >
                <Icon
                  name="list"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                all-enrolled
              </Menu.Item>
              <Divider />
              <Menu.Item
                active={listFilter === 'new-members'}
                onClick={() => handleLFChange('new-members')}
              >
                <Icon
                  name="gift"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                new-members
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'event-today'}
                onClick={() => handleLFChange('event-today')}
              >
                <Icon
                  name="calendar"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                event-today
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'needs-review'}
                onClick={() => handleLFChange('needs-review')}
              >
                <Icon
                  name="check"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                needs-review
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'msg-unread'}
                onClick={() => handleLFChange('msg-unread')}
              >
                <Icon
                  name="chat"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                msg-unread
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'missed-call'}
                onClick={() => handleLFChange('missed-call')}
              >
                <Icon
                  name="phone"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                missed-call
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'inactive-2-days'}
                onClick={() => handleLFChange('inactive-2-days')}
              >
                <Icon
                  name="bookmark"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                inactive-2-days
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'inactive-5-days'}
                onClick={() => handleLFChange('inactive-5-days')}
              >
                <Icon
                  name="bookmark outline"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                inactive-5-days
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'todo-99454'}
                onClick={() => handleLFChange('todo-99454')}
              >
                <Icon
                  name="heartbeat"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                todo-99454
              </Menu.Item>
              {/*<Menu.Item
                active={listFilter === 'todo-99457'}
                onClick={() => handleLFChange('todo-99457')}
              >
                #&nbsp;&nbsp;todo-99457
              </Menu.Item>*/}
              <Menu.Item
                active={listFilter === 'todo-99457-call'}
                onClick={() => handleLFChange('todo-99457-call')}
              >
                <Icon
                  name="call square"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                todo-99457-call
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'todo-99457-review'}
                onClick={() => handleLFChange('todo-99457-review')}
              >
                <Icon
                  name="clock"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                todo-99457-review
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'todo-99458-call'}
                onClick={() => handleLFChange('todo-99458-call')}
              >
                <Icon
                  name="call square"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                todo-99458-call
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'todo-99458-review'}
                onClick={() => handleLFChange('todo-99458-review')}
              >
                <Icon
                  name="clock"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                todo-99458-review
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'sms-available'}
                onClick={() => handleLFChange('sms-available')}
              >
                <Icon
                  name="talk"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                sms-available
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'sms-not-available'}
                onClick={() => handleLFChange('sms-not-available')}
              >
                <Icon
                  name="ban"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                sms-not-available
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'with-memo'}
                onClick={() => handleLFChange('with-memo')}
              >
                <Icon
                  name="sticky note"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                with-memo
              </Menu.Item>
              {userCustomData.practiceList && <Divider />}
              {userCustomData.practiceList &&
                (userCustomData.practiceList || []).map((x) => (
                  <Menu.Item
                    key={x.key}
                    active={listFilter.substring(10) === x.key}
                    onClick={() =>
                      handleLFChange('Practice: ' + x.key)
                    }
                  >
                    <Icon
                      name="hospital"
                      style={{ float: 'left', marginRight: '10px' }}
                    />
                    {x.text.toLowerCase().replace(/\s/g, '-')}
                  </Menu.Item>
                ))}
              <Divider />
              {groupServices.includes(platformServices.CCM) && (
                <>
                  <Menu.Item
                    active={listFilter === 'ccm-members'}
                    onClick={() => handleLFChange('ccm-members')}
                  >
                    <Icon
                      name="eye"
                      style={{ float: 'left', marginRight: '10px' }}
                    />
                    ccm-members
                  </Menu.Item>
                  <Divider />
                </>
              )}
              {/*<Menu.Item
                active={listFilter === 'htn-list'}
                onClick={() => handleLFChange('htn-list')}
              >
                #&nbsp;&nbsp;htn-list
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'obesity-list'}
                onClick={() => handleLFChange('obesity-list')}
              >
                #&nbsp;&nbsp;obesity-list
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'dm-list'}
                onClick={() => handleLFChange('dm-list')}
              >
                #&nbsp;&nbsp;dm-list
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'ckd-list'}
                onClick={() => handleLFChange('ckd-list')}
              >
                #&nbsp;&nbsp;ckd-list
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'esrd-list'}
                onClick={() => handleLFChange('esrd-list')}
              >
                #&nbsp;&nbsp;esrd-list
              </Menu.Item>
              <Menu.Item
                active={listFilter === 'chf-list'}
                onClick={() => handleLFChange('chf-list')}
              >
                #&nbsp;&nbsp;chf-list
              </Menu.Item>
              <Divider />*/}
              <Menu.Item
                active={listFilter === 'disenrolled'}
                onClick={() => handleLFChange('disenrolled')}
              >
                <Icon
                  name="ban"
                  style={{ float: 'left', marginRight: '10px' }}
                />
                disenrolled
              </Menu.Item>
            </Menu>
          </div>
        )}
        <div className="header-panel box">
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h4">
                  {listFilterMap[listFilter] || listFilter}{' '}
                  <Header.Subheader>
                    <small>
                      last updated at {new Date().toLocaleString()}
                      ,&nbsp; Side-Menu:&nbsp;
                      <Dropdown
                        placeholder="side-menu on"
                        //style={{fontSize: '0.7em'}}
                        inline
                        direction="left"
                        value={showSideMenu}
                        options={[
                          { key: 'show', value: true, text: 'On' },
                          { key: 'hide', value: false, text: 'Off' },
                        ]}
                        onChange={(e, { value }) =>
                          setShowSideMenu(value)
                        }
                      />
                    </small>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                style={{ paddingTop: '0.5em' }}
              >
                <span style={{ fontWeight: 300, fontSize: '0.7em' }}>
                  Show{' '}
                </span>
                <Dropdown
                  placeholder="ALL PATIENTS"
                  style={{ fontSize: '0.7em' }}
                  inline
                  scrolling
                  direction="left"
                  value={showParam || 'all'}
                  options={[
                    {
                      key: 'all',
                      value: 'all',
                      text: 'ALL PATIENTS',
                    },
                    {
                      key: userCustomData.id,
                      value: userCustomData.id,
                      text:
                        'ONLY MY PATIENTS (' +
                        userCustomData.name +
                        ')',
                    },
                    ...physicianList,
                  ]}
                  onChange={(e, { value }) => {
                    setShowParam(value);
                  }}
                />
                <span style={{ fontWeight: 300, fontSize: '0.7em' }}>
                  {' '}
                  and Sort by{' '}
                </span>
                <Dropdown
                  style={{ fontSize: '0.7em' }}
                  inline
                  direction="left"
                  value={sortParam || 'measureDateDesc'}
                  options={sortParams}
                  onChange={(e, { value }) => {
                    setSortParam(value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div
          className="messages box"
          style={{ padding: '1em', paddingBottom: '5em' }}
        >
          <ContractWarning
            signedContract={(userCustomData || {}).signedContract}
          />
          <StitchMembersProvider>
            <ContentTable listFilter={listFilter} />
          </StitchMembersProvider>
        </div>
      </div>
    );
  }
}
