export const senderTypes = {
  USER: 'USER',
  MEMBER: 'MEMBER',
  AUTO: 'AUTO',
  PROSPECT: 'PROSPECT',
};

export const platformServices = {
  RPM: 'RPM',
  CCM: 'CCM',
};

export const userTitles = [
  { key: 'rn', text: 'Registered Nurse - RN', value: 'RN' },
  { key: 'md', text: 'Doctor of Medicine - MD', value: 'MD' },
  { key: 'cm', text: 'Care Manager - CM', value: 'CM' },
  { key: 'pa', text: 'Physician Assistant - PA', value: 'PA' },
  { key: 'np', text: 'Nurse Practitioner - NP', value: 'NP' },
  {
    key: 'lpn',
    text: 'Licensed Practical Nurse - LPN',
    value: 'LPN',
  },
  {
    key: 'cna',
    text: 'Certified Nurse Assistant - CNA',
    value: 'CNA',
  },
  {
    key: 'cns',
    text: 'Clinical Nurse Specialist - CNS',
    value: 'CNS',
  },
  { key: 'cnm', text: 'Certified Nurse Midwive - CNM', value: 'CNM' },
  { key: 'lscsw', text: 'Social Worker - LSCSW', value: 'LSCSW' },
  { key: 'other', text: 'Other', value: 'Other' },
];

export const memberTitles = [
  { key: 'none', text: '', value: '' },
  { key: 'Mr.', text: 'Mr.', value: 'Mr.' },
  { key: 'Ms.', text: 'Ms.', value: 'Ms.' },
  { key: 'Rev.', text: 'Rev.', value: 'Rev.' },
  { key: 'Dr.', text: 'Dr.', value: 'Dr.' },
];

export const chronicConditions = [
  {
    key: 'alcohol_abuse',
    text: 'Alcohol Abuse',
    value: 'Alcohol Abuse',
  },
  {
    key: 'alzheimer',
    text: "Alzheimer's Disease/Dementia",
    value: "Alzheimer's Disease/Dementia",
  },
  { key: 'arthritis', text: 'Arthritis', value: 'Arthritis' },
  { key: 'asthma', text: 'Asthma', value: 'Asthma' },
  {
    key: 'afib',
    text: 'Atrial Fibrilation',
    value: 'Atrial Fibrilation',
  },
  {
    key: 'autism',
    text: 'Autism Spectrum Disorders',
    value: 'Autism Spectrum Disorders',
  },
  { key: 'cancer', text: 'Cancer', value: 'Cancer' },
  {
    key: 'ckd',
    text: 'Chronic Kidney Disease',
    value: 'Chronic Kidney Disease',
  },
  {
    key: 'copd',
    text: 'Chronic Obstructive Pulmonary Disease',
    value: 'COPD',
  },
  { key: 'depression', text: 'Depression', value: 'Depression' },
  { key: 'diabetes', text: 'Diabetes', value: 'Diabetes' },
  {
    key: 'drug_abuse',
    text: 'Drug Abuse/Substance Abuse',
    value: 'Drug Abuse/Substance Abuse',
  },
  {
    key: 'hiv_aids',
    text: 'HIV/AIDS',
    value: 'HIV/AIDS',
  },
  { key: 'chf', text: 'Heart Failure', value: 'Heart Failure' },
  {
    key: 'hepatitis',
    text: 'Hepatitis (Chronic Viral B & C)',
    value: 'Hepatitis',
  },
  {
    key: 'hyperlipidemia',
    text: 'Hyperlipidemia',
    value: 'Hyperlipidemia',
  },
  {
    key: 'hypertension',
    text: 'Hypertension',
    value: 'Hypertension',
  },
  {
    key: 'ischemic_heart_disease',
    text: ' Ischemic Heart Disease',
    value: ' Ischemic Heart Disease',
  },
  {
    key: 'osteoporosis',
    text: 'Osteoporosis',
    value: 'Osteoporosis',
  },
  { key: 'obesity', text: 'Obesity', value: 'Obesity' },
  {
    key: 'schizophrenia',
    text: 'Schizophrenia/Other Psychotic Disorders',
    value: 'Schizophrenia/Other Psychotic Disorders',
  },
  { key: 'stroke', text: 'Stroke', value: 'Stroke' },
];

export const allergyOptions = {
  category: [
    {
      key: 'food',
      text: 'Food',
      value: 'Food',
    },
    {
      key: 'medical',
      text: 'Medical',
      value: 'Medical',
    },
    {
      key: 'skin',
      text: 'Skin',
      value: 'Skin',
    },
    {
      key: 'environment',
      text: 'Environment',
      value: 'Environment',
    },
  ],
  reaction: [
    { key: 'mild', text: 'Mild', value: 'Mild' },
    { key: 'moderate', text: 'Moderate', value: 'Moderate' },
    { key: 'severe', text: 'Severe', value: 'Severe' },
  ],
  name: [
    { key: 'egg', text: 'Egg', value: 'Egg' },
    { key: 'milk', text: 'Milk', value: 'Milk' },
    { key: 'peanuts', text: 'Peanuts', value: 'Peanuts' },
    { key: 'soy', text: 'Soy', value: 'Soy' },
    { key: 'wheat', text: 'Wheat', value: 'Wheat' },
    { key: 'shelfish', text: 'Shelfish', value: 'Shelfish' },
    { key: 'fish', text: 'Fish', value: 'Fish' },
    { key: 'tree_nuts', text: 'Tree Nuts', value: 'Tree Nuts' },
    { key: 'latex', text: 'Latex', value: 'Latex' },
    {
      key: 'penicillin',
      text: 'Penicillin',
      value: 'Penicillin',
    },
    {
      key: 'local_anesthetics',
      text: 'Local Anesthetics',
      value: 'Local Anesthetics',
    },
    {
      key: 'contrast_dye',
      text: 'Contrast Dye',
      value: 'Contrast Dye',
    },
    { key: 'nsaids', text: 'NSAIDs', value: 'NSAIDs' },
    { key: 'soaps', text: 'Soaps', value: 'Soaps' },
    { key: 'cream', text: 'Cream', value: 'Cream' },
    { key: 'cologne', text: 'Cologne', value: 'Cologne' },
    { key: 'sunscreen', text: 'Sunscreen', value: 'Sunscreen' },
    { key: 'shampoo', text: 'Shampoo', value: 'Shampoo' },
    { key: 'henna', text: 'Henna', value: 'Henna' },
    { key: 'pollen', text: 'Pollen', value: 'Pollen' },
    { key: 'mold', text: 'Mold', value: 'Mold' },
    {
      key: 'grass_or_weed',
      text: 'Grass or Weed',
      value: 'Grass or Weed',
    },
    { key: 'pet_dander', text: 'Pet Dander', value: 'Pet Dander' },
    { key: 'cockroaches', text: 'Cockroaches', value: 'Cockroaches' },
    {
      key: 'dust_mites',
      text: 'Dust Mites',
      value: 'Dust Mites',
    },
  ],
};

export const demoWorkspace = "ormahealth-demo-1234";

export const defaultErrorMessage = (error = null) => {
  return {
    success: false, 
    message: error || "An unexpected error occured!"
  }
}
